$icon-colors: "primary", "secondary", "success", "danger", "warning", "dark",
  "info";
$text-colors: "primary", "secondary", "success", "danger", "warning", "dark",
  "info", "text-light", "text-dark", "light-gray", "action", "disabled";
#task-app,
#workflow-app,
#disaster-app {
  .primary--text {
    color: var(--lc-primary) !important;
  }
  p {
    margin: 0 !important;
    font-size: inherit;
    overflow: inherit;
    text-overflow: inherit;
  }
  .confirm-dialog.v-card {
    .v-card__actions > button {
      text-transform: capitalize !important;
      &.prime {
        color: var(--lc-primary);
      }
    }
  }
  .actions-bar {
    margin: 1rem 0.5rem 0 0;
    border-radius: 4px !important;
    padding: 0.75rem 0 !important;
  }

  .task-container .action-btn.x-small {
    font-size: 1rem !important;
  }
  .primary--text.fa-question-circle {
    color: var(--lc-secondary) !important;
  }
  .flat-panel.zero-pad-panel .v-expansion-panel {
    background-color: transparent;
    .v-expansion-panel-header {
      background-color: var(--lc-background-color) !important;
    }
    .v-expansion-panel-header__icon {
      // background-color: var(--lc-secondary) !important;
      .v-icon {
        // color: var(--lc-text-light) !important;
        color: var(--lc-secondary) !important;
      }
    }
  }

  .v-expansion-panel {
    background-color: transparent !important;
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        background-color: var(--lc-background-color);
      }
    }
    .v-expansion-panel-header button {
      background-color: var(--lc-background-color);
    }
    margin: 0.5rem 0;
  }

  .tag-label {
    @extend .rounded;
    background-color: var(--lc-primary);
    color: var(--lc-text-light);
    clip-path: polygon(
      0% 0%,
      calc(100% - 1em) 0%,
      100% 50%,
      calc(100% - 1em) 100%,
      0% 100%
    );
    padding: 0.5rem 2rem 0.5rem 1rem !important;
  }

  .tab-header {
    @extend .h4;
    @extend .fw-semi-bold;
    @extend .primary--text;
    margin: 1rem 0 !important;
  }

  div.zebra-stripes {
    div.zebra-stripe-row {
      background-color: var(--lc-background-color);
      padding: 1rem 1.5rem;
      @extend .rounded;
    }
    div.zebra-stripe-row:nth-of-type(even) {
      background-color: var(--lc-bg-light);
    }
  }
}

#lender-dashboard-app {
  height: 100%;
  background: inherit !important;
  .primary--text {
    color: var(--lc-primary) !important;
  }
  p {
    padding: 0 !important;
  }
  .v-menu__content {
    box-shadow: 1px 1px 3px rgba(68, 68, 68, 0.6) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    background: #fff !important;
    //padding: 1rem 0 !important;
    .v-small-dialog__actions {
      button {
        text-transform: capitalize !important;
      }
    }
  }
  .v-autocomplete__content {
    padding: 0 !important;
  }
  .confirm-dialog.v-card {
    .v-card__actions > button {
      text-transform: capitalize !important;
      &.prime {
        color: var(--lc-primary);
      }
    }
  }
}

.lender-ui-header {
  margin: 2rem 1rem 1rem;
}
@media (min-width: 1600px) {
  .lender-ui-header {
    margin: 2rem 3rem 1rem;
  }
}

#lender-ui-card {
  height: inherit;
  background: var(--lc-bg-light) !important;
}

.lender-data-table {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background-color: tranparent !important;
  .header-1,
  .header-2 {
    width: 100% !important;
  }
  .header-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.5rem !important;
    .table-title {
      margin: auto !important;
    }
  }
  .toggled {
    color: var(--lc-primary) !important;
  }
  header {
    border-color: rgba(0, 0, 0, 0.87) !important;
    border-radius: inherit !important;
  }
  .v-card__title {
    flex-direction: column !important;
    padding: 8px 16px 0 16px !important;
    .table-title {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .v-text-field--outlined {
      border-radius: 4px !important;
    }
  }
  .v-data-table__wrapper {
    min-height: 352px !important;
    table {
      thead > tr {
        th {
          color: var(--lc-primary) !important;
          border-bottom: 0.25rem solid var(--lc-primary) !important;
          vertical-align: bottom;
          padding-bottom: 0.5rem !important;
          font-size: 0.8rem !important;
          i.text-light {
            color: var(--lc-primary) !important;
          }
          .v-icon {
            color: inherit !important;

            &.mdi-arrow-up::before {
              content: "\f0de";
              font-family: "Font Awesome 5 Pro";
              font-size: 1rem;
              color: var(--lc-secondary) !important;
              padding: 0 3px;
            }
            &.mdi-arrow-up::after {
              content: "\f0dd";
              font-family: "Font Awesome 5 Pro";
              font-size: 1rem;
              opacity: 1 !important;
              top: 0px;
              left: 1.5px;
              transform: skewX(10deg);
              background: none !important;
              font-weight: 500 !important;
              color: var(--lc-secondary) !important;
            }
            &.text-light {
              color: var(--lc-primary) !important;
            }
            &.text-info {
              color: var(--lc-info) !important;
            }
          }

          &.active.asc {
            .v-icon {
              color: inherit !important;

              &.mdi-arrow-up::before {
                content: "\f0de";
                font-family: "Font Awesome 5 Pro";
                font-size: 1rem;
                font-weight: 900;
                color: var(--lc-primary) !important;
                padding: 0 3px;
              }
              &.mdi-arrow-up::after {
                content: "\f0dd";
                font-family: "Font Awesome 5 Pro";
                font-size: 1rem;
                opacity: 1 !important;
                top: 0px;
                left: 1.5px;
                transform: skewX(10deg);
                background: none !important;
                font-weight: 500 !important;
                color: var(--lc-secondary) !important;
              }
              &.text-light {
                color: var(--lc-primary) !important;
              }
              &.text-info {
                color: var(--lc-info) !important;
              }
            }
          }
          &.active.desc {
            .v-icon {
              color: inherit !important;

              &.mdi-arrow-up::before {
                content: "\f0de";
                font-family: "Font Awesome 5 Pro";
                font-size: 1rem;
                font-weight: 900;
                color: var(--lc-primary) !important;
                padding: 0 3px;
              }
              &.mdi-arrow-up::after {
                content: "\f0dd";
                font-family: "Font Awesome 5 Pro";
                font-size: 1rem;
                opacity: 1 !important;
                top: 0px;
                left: 1.5px;
                transform: skewX(10deg);
                background: none !important;
                font-weight: 500 !important;
                color: var(--lc-secondary) !important;
              }
              &.text-light {
                color: var(--lc-primary) !important;
              }
              &.text-info {
                color: var(--lc-info) !important;
              }
            }
          }

          .v-data-table-header__sort-badge {
            background: var(--lc-primary) !important;
            color: var(--lc-text-light) !important;
          }
        }
      }
      tbody {
        background-color: transparent !important;
      }
      tbody > tr {
        td,
        a {
          background-color: none !important;
          font-size: 0.8rem !important;
          color: var(--lc-text-dark) !important;
        }
        a {
          color: var(--lc-info) !important;
        }
      }
      tbody tr:nth-of-type(odd) {
        background-color: #fff;
      }
      tbody tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
    }
  }

  &.no-header-styles {
    .v-data-table__wrapper {
      table {
        thead > tr {
          //  &:last-child > th {
          //   border: none !important;
          // }
          th {
            background-color: transparent !important;
            color: var(--lc-text-dark) !important;
            font-weight: normal !important;
          }
        }
      }
    }
  }
  .action-btn {
    padding: 0 2px !important;
    color: var(--lc-primary) !important;
    font-size: 1rem !important;
    &:not([disabled]):hover {
      transform: scale(1.3);
    }
    &[disabled] {
      border: red;
      color: var(--lc-disabled) !important;
    }
    &.x-small {
      font-size: 0.8rem !important;
    }
    &.small {
      font-size: 0.9rem !important;
    }
  }
  @each $color in $icon-colors {
    .action-btn.icon-#{$color}:not([disabled]) {
      color: var(--lc-#{$color}) !important;
    }
  }

  .lender-data-table__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .page-size {
      max-width: 96px;
    }
    .pagination {
      box-shadow: none !important;
    }
  }
  &.inner-table {
    .v-data-table__wrapper {
      min-height: 320px !important;
    }
  }
  &.free-inner-table {
    .v-data-table__wrapper {
      min-height: auto !important;
    }
  }
}

.inbox-document-table {
  .v-data-table__wrapper {
    min-height: auto !important;
    table {
      .v-data-table-header {
        height: 40px !important;
        tr {
          th {
            color: var(--lc-text-dark) !important;
            font-size: 0.8rem !important;
            border-bottom: none !important;
          }
        }
      }
      tbody {
        background-color: transparent !important;
      }
      tbody tr:nth-of-type(odd) {
        background-color: var(--lc-background-color) !important;
      }
      tbody tr:nth-of-type(even) {
        background-color: transparent !important;
      }
    }
  }
}

.overflow-ellipses-text {
  width: 15rem;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.lender-template-tabs {
  .lender-ui-tab.v-tab {
    padding: 0 2rem !important;
    font-size: 0.875rem;
    color: var(--lc-text-light) !important;
    border-bottom: solid 1px var(--lc-secondary) !important;
    justify-content: start !important;
    text-transform: capitalize !important;
    @extend .h6;
    margin-bottom: 0 !important;
    @extend .fw-semi-bold;
    letter-spacing: 0 !important;

    &.v-tab--active {
      background-color: var(--lc-secondary) !important;
    }
  }

  .v-tabs-bar {
    padding: 1rem 0 !important;
    background-color: var(--lc-primary) !important;
    min-width: 320px !important;
    // margin: 2rem 4rem !important;
  }

  .v-tabs-items {
    background-color: var(--lc-background-color) !important;
  }

  .lender-ui-tab-item {
    background: inherit !important;
    margin: 2rem 4rem !important;
  }
}
.lender-dashboard-tabs {
  &.v-tabs--icons-and-text {
    .v-tabs-bar .v-tab {
      flex-direction: row-reverse;
      height: 48px;
      .v-icon {
        margin: 0 4px !important;
        color: var(--lc-secondary) !important;
      }
    }
  }
  &.v-tabs {
    flex: 1 !important;
  }
  .v-tabs-bar {
    background: inherit !important;
    margin-bottom: 1rem !important;
  }
  .v-tabs-items {
    background: inherit !important;
    overflow: visible !important;
  }
  .v-tab {
    padding: 4px !important;
    font-size: 0.7rem !important;
    &.v-tab--active {
      font-weight: bold;
      color: var(--lc-primary) !important;
      border-bottom: 2px solid var(--lc-secondary) !important;
    }
  }
}

.los-template-tabs,
.los-dashboard-tabs {
  &.v-tabs--icons-and-text {
    .v-tabs-bar .v-tab {
      flex-direction: row-reverse;
      height: 48px;
      .v-icon {
        margin: 0 4px !important;
        color: var(--lc-secondary) !important;
      }
    }
  }
  .v-tabs-bar {
    background: inherit !important;
    margin-bottom: 2rem !important;
  }
  .v-tabs-items {
    background: inherit !important;
    overflow: visible !important;
  }
  .v-tab {
    padding: 4px !important;
    font-size: 1rem !important;
    margin-right: 1rem !important;
    &.v-tab--active {
      font-weight: bold;
      color: var(--lc-primary) !important;
      border-bottom: 2px solid var(--lc-secondary) !important;
    }
  }
}
.lender-template-tabs .fico-tabs .v-tabs-bar {
  background-color: transparent !important;
  padding: 0 !important;
}
.lender-template-tabs .fico-tabs .v-tabs-bar .v-tab {
  padding: 1rem !important;
}
.lender-template-tabs .fico-tabs .v-tabs-bar .v-tab--active {
  background-color: var(--lc-background-color) !important;
}

.task-dashboard {
  background: inherit !important;
}

.task-container {
  height: 100%;
  // margin: 0 1rem 1rem !important;
  @each $color in $text-colors {
    .#{$color}-text {
      color: var(--lc-#{$color}) !important;
    }
  }
  .v-expansion-panel-header {
    background-color: var(--lc-background-color) !important;
  }
  .v-expansion-panel-header__icon {
    // background-color: var(--lc-secondary) !important;
    // border-radius: 50% !important;
    .v-icon {
      color: var(--lc-action) !important;
    }
  }
  .v-menu__content {
    box-shadow: 1px 1px 3px rgba(68, 68, 68, 0.6) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    background: #fff !important;
    // padding: 1rem 0 !important;
    .v-small-dialog__actions {
      button {
        text-transform: capitalize !important;
      }
    }
  }
  .v-select-list {
    .v-list-item {
      text-transform: none !important;
    }
  }
  .v-tooltip__content {
    opacity: 1 !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    padding: 0.25rem;
    background-color: var(--lc-bg-light) !important;
  }
  .loan-info-sheet {
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    height: 100% !important;
    width: 40% !important;
  }
  .sub-header {
    margin: 1rem 0 1rem 0;
    span {
      color: var(--lc-primary);
      font-size: 1.125rem;
      font-weight: normal;
    }

    // ::before {
    //   content: "\f04b";
    //   font-weight: 900;
    //   font-family: "Font Awesome 5 Pro";
    //   padding-right: 8px;
    // }
  }
  .info-box {
    font-size: 0.9rem !important;
    display: flex !important;
    flex-direction: column !important;
    span:nth-child(1) {
      color: var(--lc-text-dark) !important;
      font-weight: normal !important;
    }
    span:nth-child(2) {
      color: var(--lc-primary);
      font-weight: normal !important;
    }
  }
  .kyc-box {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.03);
    padding: 1rem;
  }
  .task-footer {
    // margin: 3rem 0 0 0 !important;
    .action-group {
      gap: 1rem !important;
    }
    .action-select {
      text-transform: capitalize !important;
      max-width: 194px !important;
      .v-text-field__details {
        display: none !important;
      }
    }
  }
  .action-btn {
    padding: 0 2px !important;
    color: var(--lc-primary) !important;
    font-size: 1rem !important;
    &.x-small {
      font-size: 0.8rem !important;
    }
    &.small {
      font-size: 0.9rem !important;
    }
    &:not([disabled]):hover {
      transform: scale(1.3);
    }
    &[disabled] {
      color: var(--lc-disabled) !important;
    }
  }
  @each $color in $icon-colors {
    .action-btn.icon-#{$color}:not([disabled]) {
      color: var(--lc-#{$color}) !important;
    }
  }
}

#progress-item,
#history-item {
  .v-stepper__step--complete {
    .v-stepper__step__step {
      background: var(--lc-success) !important;
    }
  }
}

#inbox-item {
  .message-thread {
    list-style: none !important;
    padding: 0 !important;
    // li:nth-child(even) {
    //   background-color: var(--lc-primary) !important;
    //   color: var(--lc-text-light) !important;
    //   margin-left: 42px !important;
    // }
  }
}

.lender-progress-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  .lender-progress-bar-step {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    background: var(--lc-primary);
    margin: 0.5rem 0 !important;
    @extend .rounded;
    padding: 0.5rem 2rem !important;
    height: 36px;
    clip-path: polygon(
      calc(100% - 1em) 0%,
      100% 50%,
      calc(100% - 1em) 100%,
      0% 100%,
      1em 50%,
      0% 0%
    );

    &:first-child {
      clip-path: polygon(
        0% 0%,
        calc(100% - 1em) 0%,
        100% 50%,
        calc(100% - 1em) 100%,
        0% 100%
      );
      padding: 0.5rem 2rem 0.5rem 1rem !important;
    }
    &:last-child {
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 1em 50%, 0% 0%);
      padding: 0.5rem 1rem 0.5rem 2rem !important;
    }

    .lender-progress-bar-step__label {
      @extend .fw-semi-bold;
      font-size: 0.8rem;
      color: var(--lc-text-light);
    }
  }
}

.lender-wf-stepper {
  box-shadow: none !important;
  .v-stepper__items {
    .v-stepper__content {
      padding: 2rem 0;
    }
  }
  .v-stepper__header {
    box-shadow: none !important;
    justify-content: start !important;

    hr {
      display: none !important;
    }
    .v-stepper__step {
      &:hover {
        background: var(--lc-primary-light) !important;
      }
      background: var(--lc-primary);
      margin: 0.5rem 0 !important;
      @extend .rounded;
      padding: 0.5rem 2rem !important;
      height: 36px;
      clip-path: polygon(
        calc(100% - 1em) 0%,
        100% 50%,
        calc(100% - 1em) 100%,
        0% 100%,
        1em 50%,
        0% 0%
      );

      .v-stepper__step__step {
        background: transparent !important;
        display: none !important;
      }
      .v-stepper__label {
        display: flex;
        align-items: center;
        text-shadow: none !important;
        font-size: 0.8rem !important;
        color: var(--lc-text-light) !important;
        white-space: nowrap;
        @extend .fw-semi-bold;
        &::before {
          content: "";
          font-weight: 500;
          font-family: "Font Awesome 5 Pro";
          font-size: 1.1rem;
          padding-right: 4px;
          color: var(--lc-text-light);
        }
      }

      &:not(.v-stepper__step--complete):not(.v-stepper__step--active):not(.v-stepper__step--error) {
        .v-stepper__label {
          &::before {
            content: "\f111";
          }
        }
      }

      &.v-stepper__step--active {
        background: var(--lc-primary-light);
        .v-stepper__label {
          &::before {
            content: "\f144";
          }
        }
      }

      &.v-stepper__step--complete {
        .v-stepper__label {
          &::before {
            content: "\f058";
            color: var(--lc-success);
          }
        }
      }

      &.v-stepper__step--error {
        background: var(--lc-danger);
        .v-stepper__label {
          &::before {
            content: "\f06a";
          }
        }
      }
    }
    .v-stepper__step:first-child {
      clip-path: polygon(
        0% 0%,
        calc(100% - 1em) 0%,
        100% 50%,
        calc(100% - 1em) 100%,
        0% 100%
      );
      padding: 0.5rem 2rem 0.5rem 1rem !important;
    }
    .v-stepper__step:last-child {
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 1em 50%, 0% 0%);
      padding: 0.5rem 1rem 0.5rem 2rem !important;
    }
  }
}

.audit-log-rows {
  &_primary {
    border-radius: 0px !important;
    border-left: solid 10px var(--lc-primary) !important;
  }
  &_secondary {
    border-radius: 0px !important;
    border-left: solid 10px var(--lc-secondary) !important;
  }
}

.primary-v-card,
.v-application .primary {
  background-color: var(--lc-primary) !important;
}

.secondary-v-card,
.v-application .secondary {
  background-color: var(--lc-secondary) !important;
}

/*.primary-vcard:before,
.primary-vcard:after {
  border-right: 10px solid #003b5c !important;
}

.secondary-vcard:before,
.secondary-vcard:after {
  border-right: 10px solid #02908b !important;
}*/

.audit-timeline {
  width: 60%;
}

.audit-log-rows_primary:before {
  left: -20px !important;
}

.audit-log-rows_primary:after {
  left: -20px !important;
  border-right-color: var(--lc-primary) !important;
}

.audit-log-rows_secondary:before {
  left: -20px !important;
}

.audit-log-rows_secondary:after {
  left: -20px !important;
  border-right-color: var(--lc-secondary) !important;
}

#task-history-item .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

#history-item {
  .v-stepper {
    .v-stepper__header {
      .v-stepper__step {
        margin: 0 2rem !important;
      }
    }
  }
}

@media screen and (max-width: 1111px) {
  .lender-data-table {
    table {
      thead > tr {
        th {
          vertical-align: text-top;
        }
      }
    }
  }
}

.flat-panel {
  z-index: unset !important;
  &.with-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px !important;
  }
}

.switch-small {
  label {
    font-size: 0.9rem !important;
  }
}

.zero-pad-panel {
  .v-expansion-panel {
    padding: 0 !important;
    .v-expansion-panel-header {
      padding: 0 !important;
    }
    .v-expansion-panel-content {
      padding: 0 !important;
      .v-expansion-panel-content__wrap {
        padding: 0 !important;
      }
    }
  }
}

span,
label {
  &.mandatory:after {
    content: "*";
    margin-left: 0.1rem;
    color: var(--lc-danger);
    font-size: 1rem;
  }
}

[v-cloak] {
  display: none !important;
}

.icon-large {
  font-size: 1.5rem !important;
}
.icon-medium {
  font-size: 1.2rem !important;
}
.icon-small {
  font-size: 1rem !important;
}
.icon-xsmall {
  font-size: 0.8rem !important;
}

// SBA DLAP Theme
#app-content {
  background-color: transparent !important;
}
.theme--light:not(.v-expansion-panels).v-application {
  background-color: var(--lc-background-color) !important;
  border-radius: 4px !important;
}
.v-card,
.v-data-table,
.v-data-table-footer {
  background-color: var(--lc-bg-light) !important;
  border-radius: 4px !important;
  box-shadow: initial !important;
}

.lender-dashboard-tabs {
  .v-tab {
    text-align: left !important;
    min-width: initial;
    margin-right: 1rem !important;
    border-bottom: 2px solid transparent;
  }
}

.v-stepper {
  background-color: var(--lc-bg-light) !important;
}
// .rounded {
//   border-radius: 4px !important;
// }

// .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
//   color: var(--lc-primary) !important;
//   &:hover {
//     color: var(--lc-primary-hover) !important;
//   }
// }

.lender-dashboard-tabs .v-tab:not(.v-tab--active) {
  font-weight: 550;
}
.lender-dashboard-tabs .v-tab.v-tab--active {
  font-weight: bold;
  border-bottom: 0.25rem solid var(--lc-secondary) !important;
}
// inputs
.v-input__slot .theme--light.v-icon {
  color: var(--lc-primary) !important;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background-color: var(--lc-background-color) !important;
}

.v-input .v-input__slot > fieldset {
  background-color: #ffffff !important;
  border: 2px solid var(--lc-primary);
  transition: 300ms ease-in-out;
}
.v-input.v-input--is-disabled .v-input__slot > fieldset {
  background-color: var(--lc-background-color) !important;
  border: 2px solid var(--lc-bg-light) !important;
  color: var(--lc-text-dark) !important;
  opacity: 1 !important;
}
.v-input .v-input__slot:hover > fieldset {
  border: 2px solid var(--lc-primary-hover);
}
.v-input .v-input__slot .v-label {
  color: var(--lc-primary) !important;
}
.v-input--radio-group {
  .v-input__slot {
    .v-label {
      color: var(--lc-text-dark) !important;
      font-size: 0.8rem !important;
    }
  }
}
.v-input .v-text-field__slot::placeholder {
  color: var(--lc-primary-hover) !important;
}

.v-radio.theme--light {
  .v-input--selection-controls__input {
    .v-icon {
      &.mdi-radiobox-blank {
        font-size: 2rem !important;
        &.primary--text {
          color: var(--lc-primary) !important;
        }
      }
      &.mdi-radiobox-marked {
        font-size: 2rem !important;
        &.primary--text {
          color: var(--lc-primary-hover) !important;
        }
      }
    }
  }
}

// Loan Info
.loan-info {
  border-radius: 4px !important;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: var(--lc-background-color);
}

// Data-table components
.lender-data-table {
  // padding: 1.5rem 1.5rem 0 !important;
  border: none !important;
  .v-card__title {
    padding: 0 !important;
  }
  .v-data-table-header__icon {
    opacity: 1;
    transition: none !important;
  }
  .v-data-table {
    margin-top: 0.75rem !important;

    .v-data-table__wrapper {
      > table {
        thead > tr.v-data-table__progress th {
          padding: 0 !important;
          background-color: transparent !important;
        }
        // thead > tr:last-child > th {
        //   border: none !important;
        // }
        tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
          border: none !important;
        }
        > tbody {
          > tr {
            > td {
              height: 40px !important;
            }
          }
        }
      }
    }
  }
  .v-data-footer {
    // font-size: 1rem !important;
    // font-weight: bold !important;
    color: var(--lc-primary) !important;
  }
  tr.v-data-table__progress {
    background-color: transparent !important;
  }
}
.lender-data-table .v-data-table__wrapper table tbody tr:nth-of-type(odd) {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.lender-data-table .v-data-table__wrapper table tbody tr:nth-of-type(even) {
  background: transparent !important;
  td {
    background-color: var(--lc-background-color) !important;
    border-top: none !important;
  }
}
.lender-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:nth-of-type(odd):hover {
  background-color: transparent !important;
  color: var(--lc-primary-hover) !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border: none !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th:first-child {
  border-radius: 4px 0 0 4px !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th:last-child {
  border-radius: 0 4px 4px 0 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child > td {
  padding-top: 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-radius: 4px 0 0 4px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-radius: 0 4px 4px 0 !important;
}

// .v-data-table > .v-data-table__wrapper {
//   border: solid 1px var(--lc-bg-light) !important;
//   border-radius: 4px 4px 0 0 !important;
// }

.v-data-table-header {
  height: 52px !important;
}
// chips
// .theme--light.v-chip:not(.v-chip--active) {
//   background-color: var(--lc-background-color) !important;
//   color: var(--lc-text-dark);
//   // font-weight: 700;
//   :hover {
//     background-color: var(--lc-bg-hover) !important;
//   }
// }

// Task App

#task-app,
#workflow-app {
  .task-container .action-btn.x-small {
    font-size: 1rem !important;
  }
  .primary--text.fa-question-circle {
    color: var(--lc-secondary) !important;
  }
  .flat-panel.zero-pad-panel .v-expansion-panel {
    background-color: transparent;
    .v-expansion-panel-header {
      background-color: var(--lc-background-color) !important;
    }
    .v-expansion-panel-header__icon {
      background-color: var(--lc-secondary) !important;
      .v-icon {
        color: var(--lc-text-light) !important;
      }
    }
  }
}

// Dialog
.v-overlay--active.theme--dark .v-overlay__scrim {
  background-color: var(--lc-dark) !important;
  opacity: 0.75 !important;
}

.footer {
  background-color: var(--lc-primary-light) !important;
  color: var(--lc-text-light) !important;
  font-size: 0.75rem;
  z-index: 1;
}

@media (max-width: 767.98px) {
  .overflow-ellipses-text {
    width: 10rem !important;
  }
}

.table-in-expansion-panel {
  padding: 0 !important;
}

.search-bar {
  .v-input__slot {
    border: 1px solid var(--lc-primary) !important;
    border-left: 0 !important;
    border-radius: 0 4px 4px 0 !important;
  }

  label {
    font-weight: 600 !important;
  }
  .search-icon {
    background-color: var(--lc-primary) !important;
    color: var(--lc-text-light) !important;
    // height: 100% !important;
    padding: 0 0.5rem !important;
    border-radius: 4px 0 0 4px !important;
    height: 38px !important;

    &.action {
      background-color: var(--lc-action) !important;
      cursor: pointer;
    }
  }

  .w-300 {
    width: 300px;
  }
}

// .alternating-expansion-panels > div {
//   border-radius: 4px !important;
//   min-height: 48px !important;
//   &:nth-child(even) {
//     background-color: var(--lc-background-color) !important;
//   }
//   .theme--light.v-item-group {
//     background-color: initial !important;
//   }
//   .v-expansion-panels.theme--light {
//     background-color: var(--lc-secondary) !important;
//     background-color: transparent !important;
//   }
//   .lender-data-table {
//     background-color: transparent !important;
//     tbody tr:nth-child(odd) {
//       background: #fff !important;
//     }
//     .v-data-table__wrapper table tbody > tr.v-row-group__header td {
//       background-color: transparent !important;
//       border-radius: 4px !important;
//       transition: 180ms ease-in-out;
//       .theme--light.v-btn.v-btn--icon {
//         color: var(--lc-secondary) !important;
//         // text-decoration-color: var(--lc-secondary) !important;
//         font-size: 1.5rem !important;
//       }
//       &:hover {
//         .theme--light.v-btn.v-btn--icon {
//           text-decoration-color: var(--lc-text-light) !important;
//         }
//         background-color: var(--lc-primary-hover) !important;
//       }
//     }
//   }
// }

.underline-brand-primary {
  text-decoration: underline;
  text-decoration-color: var(--lc-primary);
  text-decoration-thickness: 2px;
}

.underline-brand-secondary {
  text-decoration: underline;
  text-decoration-color: var(--lc-secondary);
  text-decoration-thickness: 2px;
}

.v-expansion-panel {
  .lender-data-table {
    border: none !important;
    .v-data-footer {
      background-color: var(--lc-background-color) !important;
    }
    .v-data-table__wrapper {
      background-color: var(--lc-background-color) !important;
      min-height: 352px;
      table {
        background-color: var(--lc-background-color) !important;
        thead > tr {
          &:last-child > th {
            border: none !important;
          }
          th {
            background-color: inherit !important;
            color: var(--lc-primary) !important;
          }
        }
        tbody {
          background-color: inherit !important;
          tr:nth-of-type(odd) {
            background-color: var(--lc-bg-light) !important;
          }
          tr:nth-of-type(even) {
            background-color: inherit !important;
          }
        }
      }
    }
  }
}

// Sort Dropdowns mobile
@media (max-width: 575.98px) {
  .lender-data-table.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    padding-left: 0;
    padding-right: 0;
  }
}

.lender-data-table .v-data-table-header-mobile__wrapper .v-select__slot {
  background: white !important;
  padding: 0.25rem;
  border: 2px solid var(--lc-primary) !important;
  border-radius: 4px;
}
.lender-data-table
  .v-data-table-header-mobile__wrapper
  .v-select__slot
  .v-label {
  padding-left: 0.5rem;
}
.lender-data-table
  .v-data-table-header-mobile__wrapper
  .v-select__slot
  .v-label.v-label--active {
  height: 2rem;
}

.lender-data-table
  .v-data-table-header-mobile__wrapper
  .v-text-field
  > .v-input__control
  > .v-input__slot:after {
  display: none !important;
}

.config-grid {
  .v-input--switch__thumb {
    background-color: var(--lc-primary) !important;
  }

  .v-input--is-label-active {
    .v-input--switch__thumb {
      background-color: var(--lc-secondary) !important;
    }
  }
}

#lender-dashboard-app {
  background-color: var(--lc-background-color) !important;
  @each $color in $text-colors {
    .#{$color}-text {
      color: var(--lc-#{$color}) !important;
    }
  }

  .v2-dashboard-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "profile content";

    .v2-profile {
      grid-area: profile;
    }
    .v2-content {
      grid-area: content;
      margin: 1rem 3rem;
      display: grid;
      gap: 20px 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "loan loan"
        "custom service";
    }

    .v2-loans {
      grid-area: loan;
    }

    .v2-custom-task {
      grid-area: custom;
    }

    .v2-service-req {
      grid-area: service;
    }

    .v2-custom-task,
    .v2-service-req {
      .data-row {
        .info-box {
          .data-val {
            font-size: 1rem !important;
            color: var(--lc-primary) !important;
          }
        }
      }
    }

    .v2-progress-bar {
      flex: 1;
      .v-progress-linear {
        .v-progress-linear__buffer {
          background-color: var(--lc-light-gray) !important;
        }
        .v-progress-linear__determinate {
          background-color: var(--lc-primary) !important;
        }
      }
    }
  }
}

.progressColor {
  color: var(--lc-primary);
  .v-progress-circular__underlay {
    stroke: var(--lc-disabled) !important;
  }
}

.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
  .v-icon {
  color: inherit;
  color: var(--lc-disabled) !important;
}
.survey-info-box {
  /* font-size: 0.9rem !important; */
  display: flex !important;
  flex-direction: column !important;
  span:nth-child(1) {
    color: var(--lc-text-dark) !important;
  }
  span:nth-child(2) {
    color: var(--lc-primary);
  }
}

.underwriting-template-tabs,
.survey-template-tabs {
  .underwriting-ui-tab.v-tab,
  .survey-ui-tab.v-tab {
    padding: 0 2rem !important;
    font-size: 0.875rem;
    justify-content: start !important;
    text-transform: capitalize !important;
    @extend .h6;
    margin-bottom: 0 !important;
    @extend .fw-semi-bold;
    letter-spacing: 0 !important;

    &.v-tab--active {
      background-color: var(--lc-background-color) !important;
      border-bottom: none !important;
    }
  }
  .v-tabs-bar {
    padding: 1rem 0 !important;
    min-width: 320px !important;
    background-color: inherit !important;
    height: 4rem !important;
    padding-bottom: 1px !important;
    // margin: 2rem 4rem !important;
  }

  .v-tabs-items {
    background-color: var(--lc-background-color) !important;
    color: var(--lc-primary) !important;
    border-bottom: none !important;
  }

  .underwriting-ui-tab-item,
  .survey-ui-tab-item {
    background: inherit !important;
    margin: 2rem 4rem !important;
  }
}
.underwriting-ui-card,
.survey-ui-card {
  height: inherit;
  background: var(--lc-bg-light) !important;
}
.zebra-stripes-reverse {
  div.zebra-stripe-row {
    background-color: var(--lc-bg-light);
    padding: 1rem 1.5rem;
    @extend .rounded;
  }
  div.zebra-stripe-row:nth-of-type(even) {
    background-color: var(--lc-background-color);
  }
}

.btn-menu-button:disabled {
  background-color: var(--lc-disabled) !important;
  opacity: 1 !important;
}

.pretty-link:disabled {
  color: var(--lc-disabled) !important;
  opacity: 1 !important;
  text-decoration-color: var(--lc-disabled) !important;
}

.btn.btn-secondary:disabled {
  background-color: var(--lc-disabled) !important;
  opacity: 1 !important;
}

.btn.btn-primary:disabled {
  background-color: var(--lc-disabled) !important;
  opacity: 1 !important;
}

.documentInput fieldset > legend > span.notranslate {
  color: #959595;
}
.side-bar-drawer .sidebar-content-container {
  overflow: auto !important;
}