ol.d-flex.file-types{
    margin:0em;
    padding:0em 0em 0.8em 0em;
    list-style: none;
    li{
        padding:0.4em 0.7em 0.4em 0.7em;
        margin:0rem 0.3rem;
        border:1px solid #ccc;
        font-size:0.9em;
        font-weight: 600;

        &:first-child{
            margin-left:0em;
        }
    }
}