.loginToggle {
    padding-bottom: 0.5rem;
    width: 253px;
    height: 100%;

    #loginCheckbox{
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        bottom: 0;

        +label {
            padding: 1em;
            width: min-content;
            cursor: pointer;
        }

        +label span {
            padding: 1em 2em;
            position: relative;
            z-index: 2;
        }

        +label span.login{
            // font-weight: bold;
            color: map-get($theme-colors,'primary');
            background-color: var(--lc-background-color);
        }
        +label span.register{
            font-weight: normal;
            color: map-get($theme-colors,'secondary');
        }

        &:checked {
            +label span.login{
                font-weight: normal;
                color: map-get($theme-colors,'secondary');
                background-color: var(--lc-bg-light) !important;
                padding-bottom: 0px;
            }
            +label span.register{
                // font-weight: bold;
                color: map-get($theme-colors,'primary');
                background-color: var(--lc-background-color);
            }
        }
    
    }

    #loginCheckbox ~ .toggleKnob{
        position: relative;
        top: -50%;
        left: 6px;
        width: 120px;
        background-color: #fff;
        border-radius: 26px;
        box-shadow: 0px 1px 1px rgba(0,0,0,.15);
        transition: all 200ms ease;
        
    }

    #loginCheckbox:checked ~ .toggleKnob{
        position: relative;
        top: -50%;
        left: calc(100% - 140px);
        width: 148px;
        background-color: #fff;
        border-radius: 26px;
        box-shadow: 0px 1px 1px rgba(0,0,0,.15);
        transition: all 200ms ease;
    }
    
    .loginCheckbox {
        background-color: var(--lc-bg-light);
    }
    .toggleKnob {
        background-color: var(--lc-bg-white);
        box-shadow: none !important;
        
    }
    label {
        border: 2px solid transparent;
        transition: 180ms ease-in-out;
    }

    // &:hover label {
    //     border-color: var(--lc-primary-hover);
    // }
    
}