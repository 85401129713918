body{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#app-content{
  flex: 1 0 auto;
  background-color: #F4F3F4;
}

nav{
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  z-index: 99;
  background-color: $white; 
  padding: 0 !important; 
  .respAppLogo img {
    max-height: 70px;
    padding:.5rem;
  }
  // @media (min-width: 576px) {
  //   .respAppLogo {
  //     padding: .5rem;
  //   }
  // }

  .nav-item{
    // padding: .5rem 1rem !important;
    align-items: center;
    i {
        margin-right: 8px; 
        font-weight: 500;
    }
  }
  // @media (max-width: 767.98px) {
  //   .nav-item {
  //     .dropdown-menu:not(.show) {
  //       display: block !important;
  //       position: relative !important;
  //     }

  //   }
  // }
  // @media (min-width: 768px) {
  //   .nav-item {
  //     display: flex;
  //     padding: 1rem;
  //   }
  // }
}

@media (max-width: 767.98px) {
  nav .respAppLogo {
    max-width: calc(100% - 72px) !important;
  }
}

.side_bar {
  box-shadow: 5px -5px 5px 0px $gray-500;
  ul{
    padding-left: 2rem;
  }
}


section {
  padding: 3rem 1rem;
}

footer{
  flex-shrink: 0;
  background-color: $white;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.05);
  z-index: 99;
  color: $secondary;
  padding: 1rem;
  a{
    color: $secondary;
  }
}