.btn-link,
.btn-link:hover {
  color: var(--lc-primary);
  text-decoration-color: var(--lc-action);
  text-underline-offset: 2px;
  text-decoration-thickness: 3px;
}

.btn-link:disabled {
  color:var(--lc-disabled);
  text-decoration:none;
}

.btn,
a,
td,
p {
  font-size: 14px;
}

/*
.btn-icon{
    color: map-get($theme-colors,'primary');
}
*/
.v-btn {
  letter-spacing: 0 !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}