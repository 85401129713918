// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  @include font-size($nav-link-font-size);
  font-weight: $nav-link-font-weight;
  color: $nav-link-color;
  text-decoration: if($link-decoration == none, null, none);
  @include transition($nav-link-transition);

  &:hover,
  &:focus {
    color: $nav-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    background: none;
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    &:hover,
    &:focus {
      border-color: $nav-tabs-link-hover-border-color;
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  .nav-link {
    background: none;
    border: 0;
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    @include gradient-bg($nav-pills-link-active-bg);
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    width: 100%; // Make sure button will grow
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

nav .nav-item:not(.nav-item-footer) {
  a:not(.dropdown-item) {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0;
    padding: 0 0.5rem;
    min-height: 58px;
    font-size: 14px;
  }
  @media (min-width: 576px) {
    a:not(.dropdown-item) {
      flex-direction: row;
      padding: 0 1rem;
      height: 86px !important;
    }
  }

  .dropdown-menu {
    background-color: var(--lc-background-color);
    padding: .5rem;
    min-width: 6rem !important;
    .dropdown-item {
      border-radius: 4px;
      padding: .5rem .5rem;
      transition: 180ms ease-in-out;
      &:hover {
        background-color: var(--lc-bg-light);
      }
    }
  }

}

#navFooter {
  background-color: var(--lc-background-color);
  z-index: 9999999999999 !important;
  transition: 300ms ease-in-out;
  &.off-screen {
    bottom: -68px !important;
  }
}

nav .nav-item.nav-item-footer {
  flex-grow: 1;
  i {
    margin: 0 0 .25rem !important;
  }
  a {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: .5rem;
    font-weight: bold;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: var(--lc-primary);
      i {
        color: var(--lc-secondary);
      }
    }

  }
}
nav .nav-item.current-route{
  i:not(.ignore-current-route)  {
    color: var(--lc-primary);
  }
  &:hover {
    color: var(--lc-text-light) !important;
    background-color: var(--lc-primary-light) !important;
  }
  border-bottom: 2px solid var(--lc-primary);
}
