table.dataTable thead tr{
    padding: 0.3rem 0rem !important;
    background-color: #F5F5F5;
    border: none;
}

table.dataTable th{
    font-weight: 500;
    color: #000000 !important;
    border: 0 !important;
    padding: 0.3rem 0rem 0.3rem 0.5rem !important;
}

table.dataTable.no-footer{
    border: 0 !important;
}

table.dataTable td{
    color: #000000
}

table.dataTable {
    width: 100% !important;
}