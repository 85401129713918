$text-colors: "primary", "secondary", "success", "danger", "warning", "dark",
  "info", "text-light", "text-dark", "light-gray", "action", "disabled";

#message_div {
  bottom: 10%;
  position: absolute;
  left: 25%;
  width: 60%;
}

#app-status {
  width: auto;
  height: 32px;
  g > rect {
    fill: var(--lc-secondary) !important;
  }
  g > path {
    fill: var(--lc-bg-light) !important;
  }
}

.col-2-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-9-5 {
  flex: 0 0 80%;
  max-width: 80%;
}

.tabs {
  border: 1px solid white;
}

#btnStartApplication-2 {
  display: block !important;
}

#borrower-dashboard-app {
  height: 100%;
  .primary--text {
    color: var(--lc-primary) !important;
  }
  p {
    padding: 0 !important;
  }
  .v-menu__content {
    box-shadow: 1px 1px 3px rgba(68, 68, 68, 0.6) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    padding: 1rem 0 !important;
    background: #fff;
    .v-small-dialog__actions {
      button {
        text-transform: capitalize !important;
      }
    }
  }
  .confirm-dialog.v-card {
    .v-card__actions > button {
      text-transform: capitalize !important;
      &.prime {
        color: var(--lc-primary);
      }
    }
  }
}

#loan-data-table,
#inbox-data-table {
  // border: 1px solid rgba(0, 0, 0, 0.15) !important;
  .v-data-table__wrapper {
    min-height: 320px !important;
    table {
      thead > tr {
        th {
          background-color: var(--lc-primary) !important;
          color: var(--lc-text-light) !important;
          font-size: 0.8rem !important;
          .v-icon {
            color: inherit !important;
          }
          .v-data-table-header__sort-badge {
            color: var(--lc-primary) !important;
            background: var(--lc-text-light) !important;
          }
        }
      }
      tbody > tr {
        td {
          background-color: transparent !important;
          font-size: 0.8rem !important;
          color: var(--lc-text-dark) !important;
        }
      }
      tbody tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
    }
  }
  &.inner-table {
    .v-data-table__wrapper {
      min-height: 320px !important;
    }
  }
  &.free-inner-table {
    .v-data-table__wrapper {
      min-height: auto !important;
    }
  }
}

.std-data-table {
  .v-data-table__wrapper {
    table {
      thead > tr {
        th {
          background-color: var(--lc-primary) !important;
          color: var(--lc-text-light) !important;
          font-size: 0.8rem !important;
          .v-icon {
            color: inherit !important;
          }
          .v-data-table-header__sort-badge {
            color: var(--lc-primary) !important;
            background: var(--lc-text-light) !important;
          }
        }
      }
      tbody > tr {
        td {
          background-color: transparent !important;
          font-size: 0.8rem !important;
          color: var(--lc-text-dark) !important;
        }
      }
      //   tbody tr:nth-of-type(even) {
      //     background-color: rgba(0, 0, 0, 0.05) !important;
      //   }
    }
  }
  &.inner-table {
    .v-data-table__wrapper {
      min-height: 320px !important;
    }
  }
  &.free-inner-table {
    .v-data-table__wrapper {
      min-height: auto !important;
    }
  }
}

.los-template-tabs {
  .v-tabs-bar {
    background: inherit !important;
    margin-bottom: 1rem !important;
  }
  .v-tabs-items {
    background: white !important;
    border-radius: 4px;
    padding: 2rem;
    overflow: visible !important;
  }
  .v-tab {
    padding: 4px !important;
    font-size: 0.7rem !important;
    &.v-tab--active {
      font-weight: bold;
      color: var(--lc-primary) !important;
      border-bottom: 2px solid var(--lc-secondary) !important;
    }
  }
}

.sba-template-tabs {
  .v-tabs-bar {
    background: inherit !important;
  }
  .v-tabs-items {
    background: var(--lc-background-color);
    border-radius: 4px;
    // padding: 2rem;
    overflow: visible !important;
  }
  .v-tab-item {
    padding: 0 1rem !important;
    border: none !important;
  }
  .v-tab {
    // padding: 0 1rem !important;
    // border: none !important;
    // color: var(--lc-primary) !important;
    // font-size: 0.875rem !important;
    // text-transform: none !important;
    // &.v-tab--active {
    //   font-weight: 600;
    // }
    // &:not(.v-tab--active) > span.tabtitle {
    //   border-bottom: 2px solid var(--lc-secondary);
    //   padding-bottom: 5px;
    // }
    padding: 0 2rem !important;
    font-size: 0.875rem;
    justify-content: start !important;
    text-transform: capitalize !important;
    @extend .h6;
    margin-bottom: 0 !important;
    @extend .fw-semi-bold;
    letter-spacing: 0 !important;

    &.v-tab--active {
      background-color: var(--lc-background-color) !important;
      border-bottom: none !important;
    }
  }
}

$vtab-padding-top: 1.8rem;
$vtab-padding-bottom: 1.8rem;
$vtab-padding-left: 1rem;
$vtab-padding-right: 1rem;
.borrower-tabs {
  //background-color: var(--lc-primary) !important;
  color: var(--lc-bg-white) !important;
  justify-content: space-between !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  padding: $vtab-padding-top $vtab-padding-right $vtab-padding-bottom
    $vtab-padding-left !important;
  font-weight: normal;
  &.v-tab--active.v-tab[visited],
  &.v-tab--active.v-tab[visited] .fa-circle,
  &.v-tab--active,
  &.v-tab--active .fa-circle {
    background-color: var(--lc-action) !important;
    color: var(--lc-bg-white) !important;
    // border-color: var(--lc-secondary) !important;
    // font-weight: bold !important;
    // //color: var(--lc-secondary) !important;
    // > div > span {
    //   text-decoration: underline;
    //   text-decoration-color: var(--lc-secondary) !important;
    //   text-decoration-thickness: 0.25rem;
    //   text-underline-offset: 0.25rem;
    // }
  }
  // &.v-tab[haserror],
  // &.v-tab[haserror] .fa-circle {
  //   color: var(--lc-danger) !important;
  //   border-bottom: solid 1px var(--lc-secondary) !important;
  // }
  &.v-tab--active:before {
    display: none;
  }
  &[visited$="visited"],
  &[visited$="visited"] .fa-circle {
    font-weight: bold !important;
    color: var(--lc-bg-white) !important;
    border-bottom: solid 1px var(--lc-secondary) !important;
  }
  &:not(:first-of-type):after {
    //content: "";
    z-index: -1;
    position: absolute;
    //right: $vtab-padding-right;
    bottom: 60%;
    top: calc(-50% + 12px);
    transform: translateX(-12px);
    width: 2px;
    background-color: #e0e0e0;
  }
  &[visited]:after {
    background-color: var(--lc-primary) !important;
    color: var(--lc-bg-white) !important;
  }
  &.sub-stepper:after {
    top: calc(-50% + 7px) !important;
  }
  .subtab-icon {
    transform: translateX(-6px);
  }
  border-bottom: solid 1px var(--lc-secondary) !important;
}

.borrower-tab-layout {
  .v-tabs-bar {
    flex: 0 0 auto !important;
    width: 24%;
    max-width: 320px !important;
    background-color: var(--lc-primary) !important;
  }
  .v-tabs-items {
    background-color: inherit !important;
    flex: 0 0 auto !important;
    width: calc(100% - 320px);
    // border-bottom: solid 1px var(--lc-secondary) !important;
  }
}

.borrower-action-layout {
  display: flex;
  background-color: var(--lc-primary);
  justify-content: end;
  .btn-panel {
    flex-direction: row;
    background-color: var(--lc-bg-light);
    width: calc(100% - 24%) !important;
  }
  @media (min-width: 1500px) {
    .btn-panel {
      width: calc(100% - 320px);
    }
  }
  #filler-panel {
    width: 24%;
    max-width: 320px !important;
    background-color: var(--lc-primary) !important;
  }
}

.v-tabs-slider-wrapper {
  display: none;
}

.v-input__slot {
  box-shadow: none !important;
}

.application-status {
  width: fit-content;
}

.documentInput .v-input__prepend-outer {
  display: none !important;
}
.profile-start-app-btn {
  z-index: 1;
  position: absolute;
  right: 3rem;
  top: 7rem;
}
#register input.form-control::-webkit-input-placeholder {
  color: var(--lc-primary) !important;
  letter-spacing: 0.25rem;
}
#register input.form-control::-moz-placeholder {
  color: var(--lc-primary) !important;
  letter-spacing: 0.25rem;
}
#register input.form-control:-ms-input-placeholder {
  color: var(--lc-primary) !important;
  letter-spacing: 0.25rem;
}
#register input.form-control:-moz-placeholder {
  color: var(--lc-primary) !important;
  letter-spacing: 0.25rem;
}
#register input.form-control::placeholder {
  color: var(--lc-primary) !important;
  letter-spacing: 0.25rem;
}
@media screen and (max-width: 600px) {
  .borrower-action-layout {
    .btn-panel {
      flex-direction: column;
      width: 100% !important;
      align-items: center;
    }
    #btn-panel-1,
    #btn-panel-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    #btn-panel-1 > button,
    #btn-panel-2 > button {
      margin: 0.25rem 0rem;
    }
  }
  .profile-start-app-btn {
    display: none;
  }
}
@media screen and (min-width: 1201px) {
  #damage_type small:nth-child(3) {
    padding-top: 5%;
  }
  .pr-1201-12px {
    padding-right: 12px !important;
  }
  div.address-block:nth-child(2) {
    padding-left: 12px !important;
  }
  div.address-block:nth-child(3) {
    padding-left: 12px !important;
  }
  div.address-block:nth-child(4) {
    padding-left: 12px !important;
  }
  .pb-1201-5 {
    padding-bottom: 5% !important;
  }
  #damage_type:nth-child(3) {
    padding-top: 5%;
  }
}
@media screen and (max-width: 959px) {
  footer > span.float-end {
    float: none !important;
  }
  #filler-panel {
    display: none;
  }
  .nopadmobile {
    padding: 0 !important;
  }
  .borrower-tab-layout {
    flex-direction: column;
    .v-tabs-bar {
      width: 100% !important;
      max-width: 100% !important;
    }
    .v-tabs-items {
      width: 100% !important;
    }
    .v-tab {
      max-width: 100% !important;
    }
  }
  #tab-borrower-entity,
  #tab-borrower-address,
  #tab-borrower-additional-info,
  #tab-borrower-additional-info {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .v-tabs-bar--is-mobile .content-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .borrower-action-layout {
    #filler-panel {
      display: none !important;
    }
    .btn-panel {
      width: 100% !important;
    }
    .btn-panel > .btn {
      margin: 0.25rem 0rem;
    }
    .row > * {
      padding-right: 0 !important;
    }
  }
  .sidebar-burger {
    display: none !important;
  }

  .content-body {
    padding: 1rem !important;
  }
  .content-body .card.me-10 {
    margin: 0 !important;
  }
  .get-started-panel {
    display: none;
  }
  #btnStartApplication-2 {
    display: block !important;
  }
  #myApplications {
    width: 100% !important;
  }
  .v-expansion-panel-content__wrap .pt-1200-0 {
    padding-top: 0% !important;
  }
  .v-expansion-panel-content__wrap .pr-1200-0 {
    padding-right: 0% !important;
  }
  .v-expansion-panel-content__wrap .pl-1200-0 {
    padding-left: 0% !important;
  }
}
@media screen and (min-width: 1264px) {
  .v-application .pl-0.pl-lg-3 {
    padding-left: 12px !important;
  }
}
// Add owner card design
.add-owner-card {
  height: 640px;
  overflow: auto;
}

@media screen and (max-width: 1200px), screen and (max-height: 920px) {
  .add-owner-card {
    height: 520px;
    overflow: auto;
  }
}
@media screen and (min-width: 764px) {
}
@media screen and (max-width: 768px), screen and (max-height: 728px) {
  .add-owner-card {
    height: 428px;
    overflow: auto;
  }
}

@media screen and (max-width: 480px), screen and (max-height: 528px) {
  .add-owner-card {
    height: 228px;
    overflow: auto;
  }
}

.sidebar-burger {
  position: relative;
  top: -1rem;
  left: -1rem;
  cursor: pointer;
  display: none;
}

.sidebar-icon-align {
  right: -1.8rem !important;
}

.v-window-item.tab-expanded *:not(.sidebar-burger) {
  filter: blur(1px);
  pointer-events: none;
}

.v-application--wrap {
  min-height: initial !important;
}

.profile-check-card {
  background-color: var(--lc-bg-light) !important;
  color: var(--lc-primary) !important;
}
.profile-check-inner-card {
  background-color: var(--lc-background-color) !important;
  // min-height: 325px !important;
}

#fieldsDiv,
#profileDiv .brand-bg {
  background-color: var(--lc-background-color) !important;
}

#fieldsDiv .v-input--is-disabled fieldset,
#profileDiv .v-input--is-disabled fieldset,
#ownerInfoForm .v-input--is-disabled fieldset {
  //background: lightgoldenrodyellow ;
  //z-index: 0;
  opacity: 0.49;
  background-color: #f2f2f2;
}

.app-stages > div {
  flex: 0 25%;
}

.brand-text-primary {
  color: var(--lc-primary) !important;
}

.brand-text-secondary {
  color: var(--lc-secondary) !important;
}

.brand-text-dark {
  color: var(--lc-dark) !important;
}

.brand-bg-primary {
  background-color: var(--lc-primary) !important;
}

.brand-bg-primary-dark {
  background-color: var(--lc-primary-dark, var(--lc-primary)) !important;
}

.brand-bg-secondary {
  background-color: var(--lc-secondary) !important;
}

.brand-bg-dark {
  background-color: var(--lc-dark) !important;
}

.brand-background-primary {
  background: var(--lc-primary) !important;
}

.brand-background-secondary {
  background: var(--lc-secondary) !important;
}

.brand-background-dark {
  background: var(--lc-dark) !important;
}

.tab-style,
.sub-tab-style {
  font-style: normal;
  // font-weight: bold;
  font-size: 0.9rem;
  padding-left: 0.3rem;
}

.sub-tab-style {
  font-size: 0.8rem;
  padding-left: 2rem;
}

.v-application {
  font-family: Source Sans Pro !important;
  line-height: 1.2;
}

.app-heading {
  font-weight: 400 !important;
}

.v-application .font-weight-light {
  font-weight: 400 !important;
}

.footer-text-h6 {
  font-size: 1em !important;
}

.v-chip.active {
  background: #fff !important;
  border: 1px solid var(--lc-primary) !important;
}

.tiptap-vuetify-editor {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-radius: 4px !important;
  .v-card {
    box-shadow: none !important;
  }
}

.tiptap-vuetify-editor__toolbar {
  .v-sheet.v-toolbar {
    border-radius: inherit !important;
  }
}

.v-tooltip__content {
  width: 20% !important;
  opacity: 1 !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  padding: 0.25rem;
  background-color: var(--lc-bg-light) !important;
  color: var(--lc-text-dark) !important;
}

.v-input--radio-group,
.v-input--radio-group .v-input__slot {
  margin: 0px !important;
  padding: 0px !important;
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-bottom: 3px !important;
}

.v-input--radio-group label {
  font-size: 0.8rem !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.v-btn--fab.v-btn--bottom {
  bottom: 70px !important;
}
.v-input--checkbox label {
  font-size: 0.9rem !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.tiptap-vuetify-editor__content {
  background-color: #ffffff;
}
.tiptap-vuetify-editor__toolbar {
  .v-toolbar {
    background-color: var(--lc-background-color) !important;
  }
}
#inbox-item-los {
  .message-thread {
    list-style: none !important;
    padding: 0 !important;
  }
}

.inbox-switch-small {
  label {
    font-size: 0.9rem !important;
  }
}

.v-textarea.v-input--is-label-active {
  .v-text-field__slot {
    margin-right: -48px !important;
  }
}

.v-textarea {
  .v-text-field__slot {
    margin-right: -23px !important;
  }
}

$body-font-family: "Source Sans Pro" !important;
.borrower-owner-tabs {
  justify-content: space-between !important;
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  //padding: $vtab-padding-top $vtab-padding-right $vtab-padding-bottom $vtab-padding-left !important;
  padding: 1rem;
  font-weight: normal;
  color: var(--lc-primary) !important;
  border-color: var(--lc-primary) !important;
  &.v-tab--active {
    color: var(--lc-secondary) !important;
    border-color: var(--lc-secondary) !important;
    font-weight: bold !important;
  }
}

@media screen and (max-width: 768px) {
  .v-treeview-node .no-gutters {
    flex-direction: column;
  }
  .v-treeview-node .no-gutters > div:first-child {
    display: flex;
    flex-direction: column;
  }
  .v-treeview-node .badge {
    width: fit-content;
    margin: 0.25rem 0rem;
  }
  .v-treeview-node .tree-btn {
    width: fit-content;
    margin: 0.25rem 0rem;
    flex-direction: column;
  }
}

.borrower-owner-tabs-numbers {
  color: white;
}

.v-treeview-node .no-gutters {
  flex-direction: column;
}

.v-treeview-node .no-gutters > div:first-child {
  display: flex;
  flex-direction: column;
}

.v-treeview-node .badge {
  width: fit-content;
  margin: 0.25rem 0rem;
}

.profile-details:nth-of-type(even) {
  background-color: var(--lc-primary-light);
}

.dash-container {
  .display-font-lg {
    font-size: 1.2rem !important;
  }
  @each $color in $text-colors {
    .#{$color}-text {
      color: var(--lc-#{$color}) !important;
    }
  }
  .v-expansion-panel-header__icon {
    background-color: var(--lc-secondary) !important;
    border-radius: 50% !important;
    .v-icon {
      color: var(--lc-text-light) !important;
    }
  }
  .v-menu__content {
    box-shadow: 1px 1px 3px rgba(68, 68, 68, 0.6) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
    // padding: 1rem 0 !important;
    .v-small-dialog__actions {
      button {
        text-transform: capitalize !important;
      }
    }
  }
  .v-select-list {
    .v-list-item {
      text-transform: none !important;
    }
  }
  .v-tooltip__content {
    opacity: 1 !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    padding: 0.25rem;
    background-color: var(--lc-bg-light) !important;
  }
  .loan-info-sheet {
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    height: 100% !important;
    width: 40% !important;
  }
  .sub-header {
    margin: 1rem 0 1rem 0;
    span {
      color: var(--lc-primary);
      font-weight: bold;
      font-size: 1.1rem;
      border-bottom: 3px solid var(--lc-action, var(--lc-secondary));
    }
  }
  .info-box {
    font-size: 0.9rem !important;
    display: flex !important;
    flex-direction: column !important;
  }
  @each $color in $icon-colors {
    .action-btn.icon-#{$color}:not([disabled]) {
      color: var(--lc-#{$color}) !important;
    }
  }
}
// New styles for SBA
.v2-card-wrapper {
  background: var(--lc-bg-white);
  padding: 1rem 0 !important;
  height: 100% !important;
  border-radius: 4px;

  .v2-card-header {
    padding: 0 1.5rem 0.25rem 1.5rem !important;
  }
  .v-skeleton-loader__list-item-two-line,
  .v-skeleton-loader__list-item-avatar-three-line {
    background: var(--lc-bg-white) !important;
  }
}

.v2-card-light {
  background: var(--lc-bg-white) !important;
  border-radius: 4px !important;
  padding: 0 1.5rem !important;
  .info-box {
    font-size: 0.9rem !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .data-row {
    padding: 0.5rem 1.5rem;

    &:nth-of-type(even) {
      background-color: var(--lc-background-color);
    }
  }
}

.btn {
  @extend .fw-semi-bold;
  width: fit-content !important;
  font-size: 0.875rem;
  padding: 0.5rem 1rem !important;
  border-radius: 4px !important;
  text-decoration: none !important;

  &.small {
    font-size: 0.8rem !important;
    font-weight: normal !important;
  }

  &.btn-white {
    // display: block !important;
    position: relative;
    padding: 0 !important;
    // margin-right: 1rem !important;
    margin: auto 0 !important;
    color: var(--lc-primary);
    &:not([disabled]):hover {
      transform: scale(1);
    }

    &.btn-bg-none {
      color: var(--lc-text-light) !important;
    }

    i:not(.show-icon) {
      display: none !important;
    }

    b {
      font-weight: normal !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--lc-action, var(--lc-secondary)) !important;
      bottom: -2px; //to place it in bottom
      left: 0;
    }

    &:disabled{
      opacity: 1;

     &::after {
        background-color: transparent !important;
      }
    }
  }

  &.btn-primary {
    color: var(--lc-text-light) !important;
    background: var(--lc-action, var(--lc-secondary)) !important;
    border: none !important;

    i:not(.show-icon) {
      display: none !important;
    }
  }

  &.btn-alt {
    color: var(--lc-text-dark) !important;
    background: var(--lc-light-gray) !important;
    border: none !important;
    border-radius: 2px 2px 0 0 !important;

    i {
      color: var(--lc-action, var(--lc-secondary)) !important;
    }

    &.no-bg {
      background: none !important;
    }

    &:hover {
      i:first-child {
        transform: scale(1.1);
      }
    }
  }
}

.btn-menu-button {
  @extend .fw-semi-bold;
  @extend .rounded;
  width: fit-content !important;
  font-size: 0.875rem;
  padding: 0.5rem 1rem !important;
  text-decoration: none !important;
  color: var(--lc-text-light) !important;
  background-color: var(--lc-action);
  height: fit-content !important;

  &.small {
    font-size: 0.8rem;
    i {
      padding: 0;
    }
  }

  .btn-menu-button__content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .v-divider--vertical {
      height: auto !important;
      opacity: 1;
      border-color: var(--lc-text-light);
    }
  }
}

.btn-menu-list {
  .v-list-item {
    min-height: 32px;
    font-size: 0.8rem;
    @extend .fw-semi-bold;
  }
}
.btn-menu-list {
  .v-list-item {
    min-height: 32px !important;
    .v-list-item__content {
      padding: 1px 0px 1px 0px !important;
      .v-list-item__title {
        font-size: 0.8rem !important;
        @extend .fw-semi-bold;
      }
    }
  }
}
.rounded {
  border-radius: 4px !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

#borrower-dashboard-app {
  background-color: var(--lc-background-color) !important;
  @each $color in $text-colors {
    .#{$color}-text {
      color: var(--lc-#{$color}) !important;
    }
  }

  .v2-dashboard-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "profile content";

    .v2-profile {
      grid-area: profile;
    }
    .v2-content {
      grid-area: content;
      margin: 1rem 3rem;
      display: grid;
      gap: 20px 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "messages pending"
        "current  current";
    }

    .v2-pending-loans,
    .v2-current-loans {
      grid-area: current;
    }
    .v2-mysba {
      grid-area: pending;
    }

    .v2-pending-loans {
      grid-area: pending;
      svg {
        max-height: 65px !important;
        max-width: 65px !important;
        min-height: 65px !important;
        min-width: 65px !important;
      }
    }

    .v2-messages {
      grid-area: messages;
    }

    .v2-pending-loans,
    .v2-current-loans {
      .data-row {
        .info-box {
          .data-val {
            font-size: 1rem !important;
            color: var(--lc-primary) !important;
          }
        }
      }
    }

    .v2-progress-bar {
      flex: 1;
      .v-progress-linear {
        .v-progress-linear__buffer {
          background-color: var(--lc-light-gray) !important;
        }
        .v-progress-linear__determinate {
          background-color: var(--lc-primary) !important;
        }
      }
    }
  }

  .v2-dashboard-grid-alt {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "profile content";

    .v2-profile {
      grid-area: profile;
    }
    .v2-content {
      grid-area: content;
      margin: 1rem;
      gap: 1rem;
      display: flex;
      flex-direction: column;
    }

    .v2-pending-loans {
      grid-area: pending;
    }

    .v2-messages {
      grid-area: messages;
    }

    .v2-progress-bar {
      flex: 1;
      .v-progress-linear {
        .v-progress-linear__buffer {
          background-color: var(--lc-light-gray) !important;
        }
        .v-progress-linear__determinate {
          background-color: var(--lc-primary) !important;
        }
      }
    }

    .v2-pending-loans,
    .v2-current-loans {
      .info-box {
        .data-val {
          font-size: 1.1rem !important;
          color: var(--lc-primary) !important;
        }
      }
    }
  }

  @media (max-width: 599px) {
    .v2-dashboard-grid,
    .v2-dashboard-grid-alt {
      height: 100%;
      display: flex;
      flex-direction: column;

      .v2-content {
        margin: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        .v2-pending-loans,
        .v2-current-loans {
          .info-box {
            .data-val {
              font-size: 0.9rem !important;
              color: var(--lc-primary) !important;
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 959px) {
    .v2-dashboard-grid,
    .v2-dashboard-grid-alt {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 3fr;
      grid-template-areas: "profile" "content";

      .v2-content {
        grid-area: content;
        margin: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        .v2-pending-loans,
        .v2-current-loans {
          .info-box {
            .data-val {
              font-size: 1rem !important;
              color: var(--lc-primary) !important;
            }
          }
        }
      }
    }
  }
  @media (min-width: 960px) and (max-width: 1263px) {
    .v2-dashboard-grid {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-areas: "profile content";

      .v2-content {
        grid-area: content;
        margin: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        .v2-pending-loans,
        .v2-current-loans {
          .info-box {
            .data-val {
              font-size: 1.1rem !important;
              color: var(--lc-primary) !important;
            }
          }
        }
      }
    }
    .v2-dashboard-grid-alt {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-areas: "profile content";

      .v2-content {
        grid-area: content;
        margin: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        .v2-pending-loans,
        .v2-current-loans {
          .info-box {
            .data-val {
              font-size: 1.1rem !important;
              color: var(--lc-primary) !important;
            }
          }
        }
      }
    }
  }
}

#statements-app {
  height: 100% !important;
  background-color: var(--lc-background-color) !important;
  @each $color in $text-colors {
    .#{$color}-text {
      color: var(--lc-#{$color}) !important;
    }
  }

  .v2-statement-grid {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(180px, 280px) auto;
    grid-template-areas: "sidebar statement";

    .v2-sidebar {
      grid-area: sidebar;
      color: var(--lc-text-light) !important;

      .v-skeleton-loader__list-item-two-line {
        background: var(--lc-primary) !important;
      }

      .header {
        padding: 1rem 1.5rem !important;
      }

      #statements-v-tab,
      .v-list {
        background-color: var(--lc-primary) !important;
        font-weight: 600 !important;
        color: var(--lc-text-light) !important;

        .v-subheader{
          font-size: 0.9rem !important;
          color: var(--lc-text-light) !important;
          font-weight: 600;
        }

        .subheader-divider{
          color: var(--lc-secondary) !important;
          border: 0.1rem solid var(--lc-secondary) !important;
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .v-list-item-group .v-list-item--active {
          background-color: var(--lc-secondary) !important;
          .v-list-item__icon {
            margin: auto 0 !important;
            color: var(--lc-text-light) !important;
          }
        }
        .v-list-item {
          padding: 0 1.5rem !important;
          color: var(--lc-text-light) !important;
          border-bottom: solid 1px var(--lc-action) !important;
          .v-list-item__title {
            font-size: 0.9rem !important;
          }
          .v-list-item__icon {
            margin: auto 0 !important;
            // color: var(--lc-action) !important;
          }
          // &:not(:last-child) {
          //   border-bottom: solid 1px var(--lc-action) !important;
          // }
        }
      }
    }
    .v2-content {
      grid-area: statement;
      margin: 1rem 3rem;
      .select-year {
        max-width: 120px !important;
        .v-input__control {
          border: solid 1px var(--lc-primary) !important;
        }
      }
    }

    .info-box {
      font-size: 1rem !important;

      .data-val {
        font-size: 1rem !important;
        font-weight: 500 !important;
        color: var(--lc-primary) !important;
      }
    }

    .data-row {
      padding: 1rem 1.5rem;
      &:nth-of-type(even) {
        background-color: var(--lc-background-color);
      }
    }
  }

  @media (max-width: 959px) {
    .v2-statement-grid {
      height: 100%;
      display: flex;
      flex-direction: column;

      .v2-sidebar {
        .v-list {
          z-index: 999 !important;
          position: absolute !important;
          width: 100% !important;
          background-color: var(--lc-primary) !important;
          font-weight: 600 !important;
          color: var(--lc-text-light) !important;

          .v-list-item-group .v-list-item--active {
            background-color: var(--lc-secondary) !important;
            .v-list-item__icon {
              margin: auto 0 !important;
              color: var(--lc-text-light) !important;
            }
          }
        }
      }

      .v2-content {
        margin: 1rem !important;
      }

      .info-box {
        font-size: 0.8rem !important;

        .data-val {
          font-size: 0.9rem !important;
          font-weight: 500 !important;
          color: var(--lc-primary) !important;
        }
      }
    }
  }
}

small {
  font-weight: 600;
}

.fs-9 {
  font-size: 0.9em !important;
}

// copied over from project.scss
.nav-icon {
  color: var(--lc-secondary);
}

.navbar {
  background-color: var(--lc-bg-light);
}

.navbar .lh-sm {
  color: var(--lc-primary);
  font-weight: 560;
  font-size: 0.95rem;
}

.navbar .fa-phone-alt {
  color: var(--lc-primary);
  margin-left: 0.6rem;
}

.navbar .fa-envelope {
  //color: var(--lc-primary);
  margin-left: 0.6rem;
}

.navbar > .text-muted > small {
  color: var(--lc-primary);
}

#navbarDropdown,
#tasksDropdown {
  color: var(--lc-primary);
}

.highlightable:hover {
  cursor: pointer;
  background-color: var(--lc-secondary) !important;
}

.borrower-application-guide .fa-megaphone {
  // font-size: 150%;
  position: relative;
  top: 5px;
}

.borrower-application-guide .fa-browser {
  // font-size: 150%;
  position: relative;
  top: 5px;
}

.application-guide-learn-more {
  font-size: 100% !important;
  font-weight: bold !important;
  color: var(--lc-primary) !important;
  text-decoration-line: underline;
  text-decoration-color: var(--lc-secondary);
  text-decoration-thickness: 2px;
}

.borrower-application-guide .card-header {
  background-color: var(--lc-primary) !important;
  color: var(--lc-bg-light) !important;
  font-weight: bold;
  font-size: 125%;
  border-radius: 4px 4px 0px 0px !important;
}

.hiddenTextField {
  .v-input__slot {
    background-color: var(--lc-bg-light) !important;
  }
}

.word-break-keep-all {
  word-break: keep-all !important;
}
.white-space-nowrap {
  white-space: nowrap !important;
}
#borrower-inbox-msg {
  padding: 0 !important;
  background: var(--lc-bg-white) !important;
  .sticky-top {
    background: var(--lc-bg-white) !important;
    .v2-header {
      .info-box {
        font-size: 0.9rem !important;
        display: flex !important;
        flex-direction: column !important;
        .data-val {
          font-size: 1rem !important;
          font-weight: 500 !important;
          color: var(--lc-primary) !important;
        }
      }

      #msg-type {
        font-size: 0.75rem;
      }
    }

    .close-btn {
      color: var(--lc-action) !important;
    }
  }

  .thread-messages {
    .my-response {
      background: var(--lc-background-color) !important;
    }

    .timestamp,
    .message-body {
      font-size: 0.75rem;
    }

    .reply-message {
      background: var(--lc-background-color) !important;
    }
  }
}
.v-snack {
  width: auto;
}
.center {
  text-align: center;
}
.add-borrower {
  border: 2px dashed var(--lc-disabled);
  background-color: inherit;
  border-radius: 4px;
  margin-top: 3rem;
}
.add-borrower i {
  font-size: 3rem;
}
.bg-color {
  background-color: var(--lc-background-color);
}
.loan-type .v-input--radio-group__input .v-radio:not(:first-of-type) {
  margin-top: 1rem;
}

h5 {
  font-weight: 600 !important;
}
.overflow-ellipses-text-borrower-structure {
  width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1500px) {
  .overflow-ellipses-text-borrower-structure {
    width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inspection_panel .v-expansion-panel {
  background: var(--lc-background-color) !important;
}

.new_inspection_panel .v-expansion-panel {
  background: var(--lc-background-color) !important;
  .v-card {
    background: var(--lc-background-color) !important;
  }
}

.loss-slider {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  padding-top: 1.5rem !important;
}

.loss-slider .v-slider__track-container .v-slider__track-fill {
  background-color: var(--lc-primary) !important;
  color: var(--lc-primary) !important;
  border-color: var(--lc-primary) !important;
  height: 10px;
}

.loss-slider .v-slider__track-container .v-slider__track-background.primary {
  background-color: var(--lc-background-color) !important;
  color: var(--lc-background-color) !important;
  border-color: var(--lc-background-color) !important;
  height: 10px;
}

.loss-slider .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  color: var(--lc-primary) !important;
  font-size: 14px;
  font-weight: 600;
  padding-top: 14px;
  margin-left: -7px !important;
  position: sticky !important;

  &::after {
    color: var(--lc-secondary);
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: small;
    content: "\f059";
    padding-left: 3px;
  }
}

.loss-slider-container {
  padding-top: 4rem;
}

.loss-tooltip {
  margin-top: -130px;
  width: 300px;
  background-color: var(--lc-primary);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px 10px;
}

.loss-tooltip-radio{
  width: 300px;
  background-color: var(--lc-primary);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px 10px;
  margin-left: -15px;
  margin-bottom: -4px;
  font-size: 0.75rem;
}

.loss-slider .v-slider__ticks-container--always-show .v-slider__tick {
  background-color: blue;
  height: 20px !important;
  width: 20px !important;
  border-radius: 50%;
  top: -1px !important;
  background-color: white;
  border: 2px solid var(--lc-primary);
}

.loss-stepper-overflow {
  overflow: visible !important;
  .v-stepper__items {
    overflow: visible !important;
    .v-stepper__wrapper {
      overflow: visible !important;
    }
  }
}

.loss-slider .v-slider__ticks-container.v-slider__ticks-container--always-show {
  background-color: var(--lc-background-color) !important;
  height: 10px;
}

.loss-slider .v-slider__thumb-container {
  margin-left: 8px !important;
  margin-top: 3.7px !important;
  .v-slider__thumb.primary {
    color: var(--lc-secondary) !important;
    background-color: var(--lc-secondary) !important;
  }
}

.loss-hint-image {
  height: 100px;
}
.overdue-loan-text {
  border-radius: 16px;
  border-width: 3px !important;
  padding: 6px
}

.pagination-alt-footer {
  .v-input {
    max-width: 45px !important;
    font-size: 0.75rem !important;
  }
  .footer-label {
    font-size: 0.75rem !important;
    color: var(--lc-primary) !important;
    align-self: center !important;
    padding-left: 30px !important;
    padding-right: 10px !important;
  }
  .v-pagination .v-pagination__navigation {
    background: inherit !important;
    box-shadow: none !important;
  }
  nav[role="navigation"] {
    box-shadow: none !important;
    background-color: inherit !important;
    width: fit-content !important;
    height: fit-content !important;
  }
}

.no-error .v-input__control .v-input__slot fieldset{
  border: 2px solid var(--lc-primary) !important;
}

.disabled-link{
  color: var(--lc-disabled) !important;
  text-decoration: none;
}
.tab-text-underline {
  border-bottom: solid 2px var(--lc-secondary) !important;
}
.v-date-picker-years {
  padding-right: 20px !important;

  li {
    display: inline-block;
    line-height: 24px;
    padding: 8px 10px;
    &:not(.active) {
      padding: 8px 10px;
    }
    &.active {
      padding: 8px 10px;
    }
  }
}
.v-date-picker-table .v-btn.v-btn--active {
  color: black !important
}

.documentInput fieldset > legend > span.notranslate {
  color: #959595;
}


.v-overlay--active {
  background-color: rgb(24 40 80 / 0.8);
  opacity: .75;
  .v-overlay__scrim {
    display: none;
  }
}

//**************Socure styling*******************//
//***********************************************//


.btn-socure {
  @extend .fw-semi-bold;
  width: fit-content !important;
  font-size: 0.875rem;
  text-decoration: none !important;

  &.small {
    font-size: 0.8rem !important;
    font-weight: normal !important;
  }

  &.large {
   width: 100% !important;
  }

  &.btn-socure-secondary {
    color: var(--lc-text-light) !important;
    background: var(--lc-socure-secondary) !important;
    border: none !important;

    &.disabled {
      color: var(--lc-text-light) !important;
      background: var(--lc-disabled) !important;
      border: none !important;
      pointer-events: none;
    }
  }

  &.btn-socure-primary {
    color: var(--lc-socure-primary) !important;
    border-bottom: 2px solid var(--lc-info);

    i:not(.show-icon) {
      display: none !important;
    }
  }
}

#ul_appointment_date.nav-pills
.nav-link{
  background: var(--lc-background-color);
  color: var(--lc-socure-primary);

  &.active{
    background: var(--lc-socure-primary);
    color: var(--lc-text-light);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.stat-bar {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
}
.lc-card {
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  background-color: var(--lc-background-color) !important;
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
  &.dark {
    background-color: var(--lc-primary) !important;
    .info-box {
      span {
        color: var(--lc-text-light) !important;
      }
    }
  }
  &.light {
    background-color: var(--lc-bg-light) !important;
  }

  button {
    margin-left: 2rem;
  }
}

.info-box {
  display: flex !important;
  flex-direction: column !important;
  span:nth-child(1) {
    font-size: 0.8rem !important;
    color: var(--lc-text-dark);
    font-weight: normal !important;
  }
  span:nth-child(2) {
    font-size: 1rem !important;
    color: var(--lc-primary);
    @extend .fw-semi-bold;
  }
  span {
    white-space: nowrap;
  }
}


//**************Socure styling*******************//
//***********************************************//


.btn-edd {
  @extend .fw-semi-bold;
  width: fit-content !important;
  font-size: 0.875rem;
  padding: 0.5rem 1rem !important;
  border-radius: 4px !important;
  text-decoration: none !important;
  max-height: 40px;
  
  &.btn-edd-primary {
    color: var(--lc-text-light) !important;
    background: var(--lc-primary) !important;
    border: none !important;

    &:disabled {
      color: var(--lc-text-light) !important;
      background: var(--lc-disabled) !important;
      border: none !important;
      pointer-events: none;
    }
  }
}


.v-btn__content .btnContainer span {
  color: var(--lc-text-dark)
}
