@import './bootstrap/scss/functions';
@import './bootstrap/scss/variables';
@import './bootstrap/scss/mixins';
$blue: var(--lc-primary);
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: var(--lc-danger);
$orange: #f2581c;
$yellow: var(--lc-warning);
$green: var(--lc-success);
$teal: #0c717a;
$cyan: var(--lc-info);
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
);
$theme-colors: (
  'primary': $blue,
  'brand': $red,
  'secondary': $gray-700,
  'success': $green,
  'danger': $red,
  'warning': $yellow,
  'info': $cyan,
  'light': $white,
  'dark': $gray-900,
  'background-color': $gray-200,
  'bg-light': $gray-100,
);
$primary: map-get($theme-colors, 'primary');
$secondary: map-get($theme-colors, 'secondary');
$success: map-get($theme-colors, 'success');
$info: map-get($theme-colors, 'info');
$warning: map-get($theme-colors, 'warning');
$danger: map-get($theme-colors, 'danger');
$light: map-get($theme-colors, 'light');
$dark: map-get($theme-colors, 'dark');
$background-color: map-get($theme-colors, 'background-color');
$bg-light: map-get($theme-colors, 'bg-light');
$font-family-base: 'Source Sans Pro', Arial, Tahoma;
$body-bg: $background-color;
$body-color: $dark;
$link-color: $info;
$link-decoration: underline;
$link-shade-percentage: 30%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: none;
$btn-disabled-opacity: 0.25;
@import 'bootstrap/scss/bootstrap';
@import '../sass/sba_los/index.scss';
// project specific CSS goes here
////////////////////////////////
//Variables//
////////////////////////////////
// Alert colors
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;
////////////////////////////////
//Alerts//
////////////////////////////////
// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error
.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.nav-icon {
  color: var(--lc-bg-light);
}

.navbar {
  background-color: var(--lc-primary);
}

.navbar .lh-sm {
  color: var(--lc-bg-light);
  font-weight: 560;
  font-size: 0.95rem;
}

.navbar .fa-phone-alt {
  color: var(--lc-bg-light);
  margin-left: 0.6rem;
}

.navbar .fa-envelope {
  //color: var(--lc-primary);
  margin-left: 0.6rem;
}

.navbar > .text-muted > small {
  color: var(--lc-bg-light);
}

#navbarDropdown,
#tasksDropdown {
  color: var(--lc-bg-light);
}

.highlightable:hover {
  cursor: pointer;
  background-color: var(--lc-primary-light) !important;
}

.highlightable:hover a div i {
  color: var(--lc-bg-light) !important;
}

.highlightable:hover a div {
  color: var(--lc-bg-light) !important;
}

.highlightable:hover a div span{
  color: var(--lc-bg-light) !important;
}

.highlightable:hover div div small {
  color: var(--lc-bg-light) !important;
}

.highlightable:hover > a > i {
  color: var(--lc-bg-light) !important;
}

.highlightable:hover #navbarDropdown {
  color: var(--lc-bg-light) !important;
}

.highlightable:hover #tasksDropdown {
  color: var(--lc-bg-light) !important;
}

.footer {
  padding-right: 5rem;
  &.row {
    flex: none !important;
  }
}

.borrower-application-guide .fa-megaphone {
  font-size: 150%;
  position: relative;
  top: 5px;
}

.borrower-application-guide .fa-browser {
  font-size: 150%;
  position: relative;
  top: 5px;
}

.application-guide-learn-more {
  font-size: 100% !important;
  font-weight: bold !important;
  color: var(--lc-primary) !important;
  text-decoration-line: underline;
  text-decoration-color: var(--lc-secondary);
  text-decoration-thickness: 2px;
}

.borrower-application-guide .card-header {
  background-color: var(--lc-primary) !important;
  color: var(--lc-bg-light) !important;
  font-weight: bold;
  font-size: 125%;
  border-radius: 4px 4px 0px 0px !important;
}

.footer-content-layout {
  display: flex;
}

#walkme-player {
  .walkme-in-wrapper {
    background-color: #f4f8fa !important;
    .walkme-title {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
}

.container-fluid.p-0.h-100vh > section {
  flex: 1;
}

.badge.message-badge {
  font-size: 0.55rem !important;
  padding: 0.3em 0.5em !important;
}

@media screen and (max-width: 485px) {
  .footer {
    #footer-contact-info {
      .d-flex.gap-2 {
        margin-bottom: 12%;
      }
    }
  }
}

@media (max-width: 959px) {
  .footer {
    padding-right: 0;
    padding-left: 0;
    justify-content: center !important;
    #footer-contact-info {
      display: flex;
      justify-content: center;
      .d-flex.gap-2 {
        justify-content: center;
      }
    }
    #footer-copyright {
      display: flex !important;
      width: 98%;
      justify-content: center;
    }
  }
  .footer-content-layout {
    display: block !important;
    text-align: center;
  }
  .footer-link-padding {
    padding-bottom: 1rem;
  }
}

#footer-email {
  padding-bottom: 3px;
  border-bottom: 2px solid var(--lc-secondary) !important;
  a,
  i {
    padding-bottom: 5px;
    text-decoration: none;
  }
}

#footer-phone {
  padding-bottom: 3px;
  border-bottom: 2px solid var(--lc-secondary) !important;
  a,
  i {
    padding-bottom: 5px;
    text-decoration: none;
  }
}

#footer-faq {
  padding-bottom: 3px;
  border-bottom: 2px solid var(--lc-secondary) !important;
  a,
  i {
    padding-bottom: 5px;
    text-decoration: none;
  }
}

.footer-link {
  padding-bottom: 6px;
  border-bottom: 2px solid var(--lc-secondary) !important;
  a,
  i {
    padding-bottom: 5px;
    text-decoration: none;
  }
}

.logo-login {
  max-width: 150px;
  // margin-left: 20%;
  height: auto;
  padding-bottom: 1rem;
}

@media screen and (max-width: 664px) {
  .loginImage > .blackBackground-login {
    position: initial !important;
  }
}

.two-factor-wrapper button > i {
  margin-right: 5%;
}

.sba-popup {
  width: 45%;
}

.sba-popup .content {
  overflow: hidden;
}

.sba-popup .sba-number-div {
  background-color: var(--lc-background-color);
}

.custom-highlight-row {
  border: 2px solid var(--lc-primary);
}

.v-application .mb-3 {
  margin-bottom: 6px !important;
}

.v-application .mt-2 {
  margin-top: 4px !important;
}

nav .nav-item:not(.nav-item-footer) {
  @media (min-width: 576px) {
    a:not(.dropdown-item) {
      height: 75px !important;
    }
  }
}

.disaster-map .card-container {
  min-width: 300px;
  width: 100%;
}

@media (min-width: 1020px) {
  .disaster-map .card-container {
    height: 100%;
  }
}

.disaster-map .v-data-table__wrapper {
  overflow-x: hidden;
}

.disaster-search-bar .v-input__slot {
  border: 1px solid var(--lc-primary) !important;
  border-left: 0 !important;
  border-radius: 0px 4px 4px 0px !important;
}

.disaster-search-bar label {
  font-weight: 600 !important;
}

.disaster-search-bar .search-icon {
  background-color: var(--lc-primary) !important;
  color: var(--lc-text-light) !important;
  padding: 0 0.5rem !important;
  border-radius: 4px 0 0 4px !important;
  height: 38px !important;
}

.disaster-search-sba-logo {
  // background: url("/static/images/svg/sba_logo_horizontal.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.newline-html {
  white-space: pre-wrap;
}
ul.applications {
  list-style-type: none !important;
}
.profile-icon-link-grid {
  grid-template-columns: 50px 1fr;
}

div.alert > div > ul {
  color: var(--lc-primary);
}
div.alert > button.btn-close {
  filter: invert(12%) sepia(100%) saturate(5596%) hue-rotate(353deg) brightness(85%) contrast(101%);
  opacity: 1;
}

.nav-icon {
  margin-right: 8px;
  font-weight: 500;
}

nav .nav-item.current-route,
nav .nav-item.current-route:focus {
  border-bottom: 4px solid var(--lc-secondary) !important;
}

.alert-success {
  color: var(--lc-primary) !important;
  background-color: transparent !important;
  border: 3px solid var(--lc-success) !important;
  i {
    color: var(--lc-success) !important;
  }
}
.alert-danger {
  color: var(--lc-primary) !important;
  background-color: transparent !important;
  border: 3px solid var(--lc-danger) !important;
  i {
    color: var(--lc-danger) !important;
  }
}
.alert-warning {
  color: var(--lc-primary) !important;
  background-color: transparent !important;
  border: 3px solid var(--lc-warning) !important;
  i {
    color: var(--lc-warning) !important;
  }
}
.alert-infobox {
  color: var(--lc-primary) !important;
  background-color: transparent !important;
  border: 3px solid var(--lc-primary) !important;
}
.icon-container {
  height: 60px !important;
}

.alert-info {
  color: var(--lc-primary) !important;
  background-color: transparent !important;
  border: 3px solid #b3d8eb !important;
  i {
    color: #b3d8eb !important;
  }
}
