.floating-card-background {
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;

  .with-toggle {
    margin-bottom: -30px;
  }
}
small i {
  padding-right: 0.5rem;
}
.floating-card-background-login {
  background-color: var(--lc-bg-light) !important;
  padding: 2rem;
  width: 90%;
  .with-toggle {
    margin-bottom: -30px;
  }
}

.floating-inner-card-login {
  background-color: var(--lc-background-color);
  padding: 1rem;
}

.blackBackground-login {
  max-height: 360px;
  max-width: 355px;
  background: $primary;
  opacity: 94%;
  position: absolute;
  bottom: 30%;
  left: 32px;
  border-radius: 3px;
  padding: 1em;
}
.h-100vh {
  height: 100vh;
}
span.login,
span.register {
  border-radius: 4px 4px 0px 0px;
}
.floating-card-background-login .floating-inner-card-login {
  border-radius: 4px 4px 0px 0px;
}
// #register form input {
//   border-radius: 4px 4px 4px 4px !important;
// }
// @media screen and (max-width: 959px) {
//   .blackBackground-login {
//     position: relative;
//     bottom: 0;
//     left: 0;
//   }
//   .h-100vh {
//     height: initial;
//   }
//   .resp-my-1 {
//     margin: 1rem 0rem;
//   }

//   .loginImageSBA {
//     background: url("/static/images/svg/sba-login-mobile.jpg") no-repeat center !important;
//     width: 106%;
//     min-height: 288px !important;
//     //background-size: cover;
//   }
// }

.blackBackground {
  max-height: 360px;
  max-width: 355px;
  background: var(--lc-primary);
  opacity: 94%;
  position: relative;
  width: 90%;
  bottom: -7rem;
  border-radius: 15px;
  padding: 1em;
  margin: auto;
}

// .loginImage {
//   background: url("/static/images/svg/dashboard_background.png") no-repeat;
//   background-size: cover;
//   height: 100%;
//   background-position: center;
//   position: relative;
//   aspect-ratio: 91/48;
//   max-width: 100%;
// }

// .loginImageSBA {
//   background: url("/static/images/svg/sba-login-desktop.jpg") no-repeat;
//   background-size: contain;
//   height: 100%;
//   background-position: center;
//   position: relative;
// }
@media (max-width: 959px) {
  .loginImage {
    width: auto;
    // min-height: 388px;
  }

  .loginImageSBA {
    // width: 220%;
    //min-height: 488px;
    // background: url("/static/images/svg/sba-login-mobile.jpg") no-repeat center;
    width: auto;
    min-height: 288px !important;
    background-size: contain;
  }

  .h-100vh {
    height: 100%;
  }
}
// @media screen and (max-width: 959px) {
//   .floating-card-background-login {
//     width: 100%;
//   }
//   .floating-inner-card-login {
//     padding: 3rem;
//   }
// }
// @media screen and (min-width: 960px) {
//   .loginImage .loginImageSBA {
//     height: 100%;
//   }
// }
// @media screen and (min-width: 1200px) {
//   .floating-card-background-login {
//     width: 70%;
//   }
// }
.profileImage {
  // background: url("/static/images/svg/placeholder-woman.jpg") no-repeat;
}

.dotted-border-top {
  border-top: 1px dashed #ced4da;
}
.text-e5 {
  color: #000 !important;
  opacity: 0.1;
}
.margin-y-2 {
  margin: 2rem 0rem;
}
.text-large {
  font-size: 5rem;
  line-height: 2.5rem;
}

hr.dash {
  margin-top: 15px;
  border-top: 0.3em solid var(--lc-secondary);
  margin: 0em;
  text-align: left;
  margin-bottom: 1em;
  width: 5em !important;
  opacity: 100%;
  box-shadow: none;
  background-color: transparent;
}

/* Stages Bar */
.stages-icon {
  position: relative;
  left: -1.75rem;
  font-size: 3rem;
}

.fa-stack .cornered-tr {
  position: absolute;
  top: 5px !important;
  left: 1px !important;
  line-height: 1em;
  background-color: white;
}

.stages-text {
  left: -1.75rem;
  position: relative;
}
/* Side Bar */

.sidebar-icon-align {
  right: -1.5rem;
}
.sidebar-sublist {
  display: none;
}
.sidebar-list-item.expanded .sidebar-sublist {
  display: block;
}
.sidebar-list-item.completed .border-end,
.sidebar-list-item.completed .sidebar-icon-align {
  border-color: var(--lc-primary) !important;
}
.sidebar-list-item.expanded .border-end,
.sidebar-list-item.expanded .sidebar-icon-align {
  border-color: var(--lc-danger) !important;
}
.sidebar-list-item.completed > div > span,
.sidebar-list-item.completed .text-primary,
.sidebar-list-item.completed .sidebar-icon-align {
  color: var(--lc-primary) !important;
}
.sidebar-list-item.expanded > div > span,
.sidebar-list-item.expanded .text-primary,
.sidebar-list-item.expanded .sidebar-icon-align,
.sidebar-list-item.active > div > span {
  color: var(--lc-danger) !important;
}
.sidebar-list-item.completed > div > span,
.sidebar-list-item.expanded > div > span {
  font-weight: 600;
}
.sidebar-list-item.expanded .fa-check:before {
  content: "\f111";
}
.sidebar-list-item.completed .fa-circle:before {
  content: "\f058";
}

.card-body.as-body {
  margin-top: 0rem;
  margin-bottom: 0rem;
  width: calc(100% - 0.4em);
  border-top: bottom 2px #fff;
}

.card-body.as-footer {
  margin-top: 0rem;
  margin-bottom: 0.3rem;
  width: calc(100% - 0.4em);
}

.card-body.as-header {
  margin-bottom: 0rem;
  width: calc(100% - 0.4em);
  margin-top: 0.6rem;
}

.card-body-shadow-wrapper {
  margin: 0.4rem 0.2rem 0.4rem 0.2rem;
  box-shadow: 0px 3px 5px 0px rgb(170 170 170 / 60%);
}

.footer {
  background-color: #fff !important;
}

.stage-icon-align {
  position: relative;
  top: -1rem;
}

.stages-body.active .stage-icon-align {
  color: var(--lc-secondary) !important;
}
.stages-body.active small {
  color: #000;
}
.stages-body.visited .text-muted {
  color: var(--lc-primary) !important;
}
.stages-body.visited .stage-icon-align:before {
  content: "\f058" !important;
  background: #fff;
}
.stages-body small {
  position: relative;
  top: -1rem;
}

.doc-section.uploaded {
  border-color: green;
}
.doc-section.uploaded .doc-suc-msg {
  display: block !important;
  color: green !important;
}
.doc-section.uploaded .doc-err-msg {
  display: none !important;
}

/* Custom popup */
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 10;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 20;
}

.popup h2 {
  margin-top: 0;
  color: var(--lc-primary);
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}
.top-p5 {
  top: 0.5rem;
}

.error_card {
  text-align: center;
}

.error_icon {
  color: var(--lc-light-gray) !important;
}
.v-card__title {
  word-break: break-word !important;
}

.card-bg-light {
  background-color: var(--lc-background-color) !important;
}
